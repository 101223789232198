<template>
  <div class="confirm">
    <div class="confirm__back"></div>

    <div class="confirm__main">
      <p class="confirm__text">
        <slot name="title"> </slot>
      </p>

      <div class="confirm__panel">
        <a href="#" class="confirm__button" @click.prevent="$emit('ok', true)">
          {{ okBtn }}
          <span class="confirm__num" v-if="okBtn === 'Error'">
            {{ number }}
          </span>
        </a>

        <!-- <a href="#" class="confirm__button" @click="$emit('close', false)"> Отмена </a> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    method: {
      type: String,
      required: true,
    },
  },
  emits: ['ok', 'close'],
  data: () => ({
    okBtn: 'Ок',
    number: 4,
  }),
  methods: {
    incrimentNumber() {
      this.number -= 1;
      if (this.number > 0) {
        setTimeout(() => {
          this.incrimentNumber();
        }, 1000);
      } else {
        this.okBtn = 'Подтвердить';
        this.number = 4;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;

  z-index: 100;

  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;

    width: 150px;
    height: 40px;

    box-sizing: border-box;

    color: #000000;
    font-size: 20px;

    margin: 0 15px 0;

    background: #dbdcdd;
    border-radius: 10px;

    &:hover {
      background: #45688e7a;
    }
  }

  &__num {
    margin: 0 0 0 10px;
  }

  &__text {
    font-size: 19px;
    padding: 0 15px;
    line-height: 20px;
    margin: 0 20px 25px;
    width: 100%;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    max-height: 120px;
    word-wrap: anywhere;
  }

  &__back {
    background: #22282f3f;
    height: 100%;
    width: 100%;
  }

  &__main {
    width: 450px;
    height: 200px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    position: absolute;
    top: 10%;

    background: #ffffff;
    padding: 40px 0 0;
  }

  &__panel {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto 0 30px;
  }
}

.red-color {
  background: #a80707;
  &:hover {
    background: #ff0000;
  }
}
.green-color {
  background: #1e7c0b;
  &:hover {
    background: #1e7c0b;
  }
}
</style>
