<template>
  <div class="public-container">
    <div class="public-sale">
      <div class="public-sale" v-if="!isPageLoad">
        <circle-c class="circle-load"></circle-c>
      </div>
      <div v-else class="public-sale">
        <sale-cards
          v-for="(card, index) in getGroups"
          :key="index"
          :card="card"
          :showAdminLink="showAdminLink"
          class="card"
          @openGroup="openGroup(card)"
          @deleteGroup="deleteGroup(card)"
        ></sale-cards>

        <teleport to="body">
          <transition name="fade">
            <group-infomation
              v-if="showFullInfo"
              :groupInfo="currentGroup"
              :currentAPI="createdClass"
              :showAdminLink="showAdminLink"
              class="group-infomation"
              @closeWindow="showFullInfo = false"
            ></group-infomation>
          </transition>
        </teleport>

        <teleport to="body">
          <popup-window
            v-if="informError"
            @ok="informError = ''"
            @close="informError = ''"
            class="group-statistic"
          >
            <template #title>
              <span class="error-info"> {{ informError }} </span>
            </template>
          </popup-window>
        </teleport>
      </div>

      <ScrollTop class="test"> </ScrollTop>
      <infinity-scroll @intersect="intersected" id="tt" class="observe" />
    </div>

    <aside class="settings" :class="{ show: getSettingsState, 'settings-height': isLoadPage }">
      <aside-menu></aside-menu>

      <settings-bar
        :currentAPI="createdClass"
        @requestNewPage="requestNewPage"
        @updateByFilter="updatePage"
      ></settings-bar>
    </aside>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SaleCards from '@/components/admin-sale/SaleCards.vue';
import GroupInfomation from '@/components/admin-sale/GroupInfomation.vue';
import CircleC from '@/components/atom/loader/Circle.vue';
import SettingsBar from '@/components/vkMenu/settings/PublicSettingsBar.vue';
import PopupWindow from '@/components/admin-sale/PopupWindow.vue';
import AsideMenu from '@/components/admin-sale/AsideMenu.vue';
import InfinityScroll from '@/components/pagination/InfinityScroll.vue';
import ScrollTop from '@/components/atom/button/scroll/ScrollTop.vue';

export default {
  computed: {
    ...mapGetters('vkConfiguration', ['getSettingsState']),
    ...mapGetters('publicSale', ['getGroups', 'isPageLoad']),
  },
  components: {
    SaleCards,
    GroupInfomation,
    CircleC,
    SettingsBar,
    PopupWindow,
    AsideMenu,
    InfinityScroll,
    ScrollTop,
  },
  data: () => ({
    cards: [],
    showFullInfo: false,
    currentGroup: {},
    showAdminLink: true,
    informError: '',
  }),
  methods: {
    ...mapMutations('vkValues', ['resetFilter']),
    ...mapActions('publicSale', [
      'requestGroups',
      'deleteAdminsGroupFromSale',
      'requestGroupByInfinity',
    ]),

    updatePage() {
      this.updateVisibleAdminLink();
      this.requestGroups();
    },

    requestNewPage() {},

    updateVisibleAdminLink(showAdminLink = true) {
      this.showAdminLink = showAdminLink;
    },

    openGroup(card) {
      this.showFullInfo = true;
      this.currentGroup = card;
    },

    deleteGroup(card) {
      this.deleteAdminsGroupFromSale(card)
        .then((resp) => {
          if (resp.message === 'access') {
            this.updatePage();
            return;
          }
          this.informError = resp.error;
        })
        .catch((err) => {
          console.log('deleteAdminsGroupFromSale', err);
          this.informError = err;
        });
    },

    async intersected() {
      if (!this.isPageLoad) {
        return;
      }

      this.requestGroupByInfinity();
    },
  },
  mounted() {
    this.updatePage();
  },
  unmounted() {
    this.resetFilter();
  },
};
</script>

<style lang="scss" scoped>
.public-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  // flex-direction: column;
  align-items: flex-start;
  font-size: 40px;
}
.text {
  margin: 20px 0 0 0;
}
.image {
  width: 150px;
  height: 150px;

  margin: 100px 0;
}
.test {
  position: fixed;
  bottom: 27px;
  right: calc(41vw - 23%);
  z-index: 15;
}
.settings {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: auto;
  padding: 0 12px;

  flex-basis: 350px;
  max-width: 350px;
  min-width: 350px;

  background-color: #f7f7f7;
  border-left: 1px solid #d8dfea;

  box-sizing: border-box;
  margin: 0 0 0 auto;

  @media (max-width: 1300px) {
    position: absolute;
    top: 165px;
    right: 0;
    visibility: hidden;
    width: 50%;
    max-width: 350px;
    height: calc(100% - 165px);
    overflow: auto;
    overflow-x: hidden;
  }
}
.show {
  visibility: visible;
}
.settings-height {
  height: auto;
  padding: 0 12px 50px 12px;
}
.public-sale {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;

  width: 100%;
}
.card {
  margin: 5px 10px;
}
.group-infomation {
  position: absolute;

  top: 0;
  left: 0;

  z-index: 100;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.circle-load {
  width: 125px;
  height: 125px;

  margin: 20px auto;
}

.group-statistic {
  position: absolute;

  top: 0;
  left: 0;

  z-index: 100;
}
.error-info {
  color: #bb1111;
  font-size: 22px;
}

.test {
  position: fixed;
  bottom: 27px;
  right: calc(41vw - 23%);
  z-index: 15;
}
</style>
